// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component115Page() {
//useNavigate const navigate = useNavigate()


const [content,setContent]= useState( {"self":["Exercice de 3 minutes :\n10 secondes de cours 75/80% VMA\n10 seconde de repos\net on répète pendant 3 minutes"]} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const action_519 =    ({Exercice,setExercice,postExercice})=> {    
        try { 
        if(Exercice.timer) { 
clearTimeout(Exercice.timer)
}


setExercice({...Exercice, Tache : Exercice.Tache?.map(t=>({...t, currentTempsRestant:null, currentTempsRestantInt:null,currentPercentage:null  })), currentRepeat:null, currentTask:null,timer:null,status:"FINISH"}  )

}
        catch (error) {
        console.error(error);
        }
      };  

const setExercicebloc0_0_7_0 =(value)=> setInternal("Exercice",value);

const action_event_519_bloc0_0_7_0 =  async(event)=>{

                            
                           var Exercice = content["Exercice"];
              
                          await action_519( { Exercice,event, setExercice:setExercicebloc0_0_7_0  }) 
              
                          };  

const action_517 =    ({Exercice,setExercice,postExercice})=> {    
        try { 
        console.log("_set_ Run Exercice!!",Exercice)
if (!Exercice || Exercice.timer || !Exercice.Tache) return;

var Exo = {...Exercice}

const runExo = async() => {
  var laps = 100;
  var currentRepeat =
    typeof Exo.currentRepeat === "number" ? Exo.currentRepeat : 1;
  var currentTask =
    typeof Exo.currentTask === "number" ? Exo.currentTask : 0;
  var status = "RUN";

  Exo.Tache[currentTask].currentTempsRestant =
    (typeof Exo.Tache[currentTask].currentTempsRestant === "number")
      ? Exo.Tache[currentTask].currentTempsRestant - laps/1000.0
      : parseInt(Exo.Tache[currentTask].Temps);
       Exo.Tache[currentTask].currentPercentage =(100.0*Exo.Tache[currentTask].currentTempsRestant)/parseFloat(Exo.Tache[currentTask].Temps); 
      
    console.log(`_set_ Repetition ${ currentRepeat} Tache ${currentTask } temps ${ Exo.Tache[currentTask].currentTempsRestant}`)
    Exo.Tache[currentTask].currentTempsRestantInt = Math.round(Exo.Tache[currentTask].currentTempsRestant)
  if (Exo.Tache[currentTask].currentTempsRestant <= 0) {
    Exo.Tache[currentTask].currentTempsRestant = null;
        Exo.Tache[currentTask].currentTempsRestantInt = null;
     Exo.Tache[currentTask].currentPercentage = null;
    currentTask++;
    console.log("_set_ Tache suivante " + currentTask)
   
    laps = 10;
    if (currentTask === Exo.Tache.length) {
   
      currentTask = 0;
      currentRepeat++;
      if (currentRepeat > parseInt(Exo.times)) {
    console.log("_set_ Exercice fini ")
        currentRepeat = null;
        currentTask = null;
        status = "FINISH";
      }
      else
       {
       console.log("_set_ Serie suivante " + currentRepeat)
       }
   
    }
  }
  else{
         console.log("_set_ Serie continue en cours " + currentRepeat)
  }

 var timer = status === "FINISH" ? null: setTimeout(runExo, laps);
  Exo={ ...Exo, timer, currentTask, currentRepeat, status }
  console.log("_set_ Exo",Exo)
 await setExercice({ ...Exo});
 
};

runExo();

}
        catch (error) {
        console.error(error);
        }
      };  

const setExercicebloc0_0_7_1 =(value)=> setInternal("Exercice",value);

const action_event_517_bloc0_0_7_1 =  async(event)=>{

                            
                           var Exercice = content["Exercice"];
              
                          await action_517( { Exercice,event, setExercice:setExercicebloc0_0_7_1  }) 
              
                          };  

const action_518 =    ({Exercice,setExercice,postExercice})=> {    
        try { 
        
if(Exercice.timer) { 
clearTimeout(Exercice.timer)
setExercice({...Exercice,timer:null,status:"PAUSE"}  )
}

}
        catch (error) {
        console.error(error);
        }
      };  

const setExercicebloc0_0_7_2 =(value)=> setInternal("Exercice",value);

const action_event_518_bloc0_0_7_2 =  async(event)=>{

                            
                           var Exercice = content["Exercice"];
              
                          await action_518( { Exercice,event, setExercice:setExercicebloc0_0_7_2  }) 
              
                          };  

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full bg-gray-700 text-white min-h-visible flex centre  items-center justify-center"}     > <div id='bloc0_0'  className={ "w-full flex flex-col gap-2 items-center justify-center h-full"}     > <div id='bloc0_0_0'  className={ "w-full text-2xl font-bold p-4"}     >{ content["self"]} </div>
            <div id='bloc0_0_1x0'  className={ "w-full hidden"}     > <img  id='bloc0_0_1x0_0'  className={ "object-cover w-full h-60 rounded-t-2xl -mb-12"}  src= "https://fs.appisyou.com/apps/benj/derf1725545691167.png"   alt="undefined" />
              <div id='bloc0_0_1x0_1'  className={ "w-full p-2 text-white bg-green-500 rounded-xl flex flex-col items-center justify-center transition-all relative"}   style = { {"backgroundColor":"\"#e41414\""}}   > <div id='bloc0_0_1x0_1_0'  className={ "w-full text-2xl font-bold p-4"}     >{ content["self"]} </div>
                <div id='bloc0_0_1x0_1_1'  className={ "w-full h-4 flex items-center justify-center"}     > <div id='bloc0_0_1x0_1_1_0'  className={ "h-4 bg-white transition-all"}   style = { {"width":" content.currentPercentage  +  \"%\" "}}   > </div></div>
                <div id='bloc0_0_1x0_1_2'  className={ "p-2 text-center font-bold text-2xl"}     >{`9`} </div></div></div>
    <div id='bloc0_0_1x1'  className={ "w-full hidden"}     > <img  id='bloc0_0_1x1_0'  className={ "object-cover w-full h-60 rounded-t-2xl -mb-12"}  src= "https://fs.appisyou.com/apps/benj/istockphoto-509366108-612x6121725545710180.jpg"   alt="undefined" />
              <div id='bloc0_0_1x1_1'  className={ "w-full p-2 text-white bg-green-500 rounded-xl flex flex-col items-center justify-center transition-all relative"}   style = { {"backgroundColor":"\"#586eda\""}}   > <div id='bloc0_0_1x1_1_0'  className={ "w-full text-2xl font-bold p-4"}     >{ content["self"]} </div>
                <div id='bloc0_0_1x1_1_1'  className={ "w-full h-4 flex items-center justify-center"}     > <div id='bloc0_0_1x1_1_1_0'  className={ "h-4 bg-white transition-all"}   style = { {"width":" content.currentPercentage  +  \"%\" "}}   > </div></div>
                <div id='bloc0_0_1x1_1_2'  className={ "p-2 text-center font-bold text-2xl"}     >{`9`} </div></div></div>
            <img  id='bloc0_0_2'  className={ "object-cover w-full h-60 rounded-t-2xl -mb-12"}  src= "https://fs.appisyou.com/apps/benj/DSC_0602-380x2201725545701841.jpg"   alt="undefined" />
            <div id='bloc0_0_3'  className={ "w-full p-2 whitespace-pre-line text-left bg-gray-800 text-white rounded-xl p-2 h-40 flex items-center justify-center text-center shadow"}     >{ content["self"]} </div>
            <div id='bloc0_0_4'  className={ "p-2  hidden"}     >{`Tape sur Go pour reprendre !`} </div>
            <div id='bloc0_0_5'  className={ "p-2 "}     >{`Tu es prêt ?  Tapes sur Go !`} </div>
            <div id='bloc0_0_6'  className={ "p-2  hidden hidden"}     >{`null / {times} `} </div>
            <div id='bloc0_0_7'  className={ "p-2 flex items-center justify-center"}     > <div id='bloc0_0_7_0'  className={ "text-white font-semibold py-2 inline-block rounded-full -mr-10 h-12 pr-5 transition-all flex items-center justify-center bg-gray-800 shadow-xl w-0"}    onClick = { action_event_519_bloc0_0_7_0} > <FontAwesomeIcon  id='bloc0_0_7_0_0'  icon={ fa.faStop}      className={ "p-2"}  /></div>
              <div id='bloc0_0_7_1'  className={ "p-4 rounded-full items-center justify-center flex-col flex w-20 h-20 border-white z-20 border-4 bg-gray-800 shadow-xl"}    onClick = { action_event_517_bloc0_0_7_1} > <FontAwesomeIcon  id='bloc0_0_7_1_0'  icon={ fa.faPlay}      className={ "text-white font-semibold inline-block rounded-full text-center w-6 h-6"}  />
                <div id='bloc0_0_7_1_1'  className={ "text-white h-10 -mb-6"}     >{`Go`} </div>
                <div id='bloc0_0_7_1_2'  className={ "h-5 w-5 bg-white rounded-full animate-ping hidden"}     > </div></div>
              <div id='bloc0_0_7_2'  className={ "text-white font-semibold py-2 inline-block rounded-full -ml-10 h-12 pl-5 transition-all flex items-center justify-center bg-gray-800 shadow-xl w-0"}    onClick = { action_event_518_bloc0_0_7_2} > <FontAwesomeIcon  id='bloc0_0_7_2_0'  icon={ fa.faPause}      className={ "p-2 "}  /></div></div></div></div></>
  
}

export default Component115Page;
