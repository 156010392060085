// Imports
          import { Link } from 'react-router-dom'
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component92Page() {
const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_0_0_0_onClick = async (e) => {
                try{ 
                      navigate('/landingpage');
                }
                catch(e){
                  console.log("Error bloc0_0_0_0_onClick ")
                }  
              };

const bloc0_0_2_onClick = async (e) => {
                try{ 
                      navigate('/aboutus');
                }
                catch(e){
                  console.log("Error bloc0_0_2_onClick ")
                }  
              };

const bloc0_0_3_onClick = async (e) => {
                try{ 
                      navigate('/contacts');
                }
                catch(e){
                  console.log("Error bloc0_0_3_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full bg-white"}     > <div id='bloc0_0'  className={ "flex flex-row w-screen items-center p-4"}     > <div id='bloc0_0_0'  className={ "p-2 bg-white w-16 h-16 w-full flex justify-center"}     > <Link    to="/landingpage"><img  id='bloc0_0_0_0'  className={ "h-full w-40   object-cover"}  onClick = { bloc0_0_0_0_onClick } src="https://fs.appisyou.com/apps/benj/MyGymLogo1714136646977.png"   style = { {"object-fit":"contain"}} alt="undefined" /></Link></div>
            <div id='bloc0_0_1'  className={ "w-full p-2 bg-white flex flex-row items-center justify-center"}     > <div id='bloc0_0_1_0'  className={ "p-2 bg-white font-semibold"}     >{`PROGRAM`} </div>
              <FontAwesomeIcon  id='bloc0_0_1_1'  icon={ fa.faAngleDown}      className={ "p-2 bg-white"}  /></div>
            <Link    to="/aboutus"><div id='bloc0_0_2'  className={ "w-full p-2 bg-white font-semibold col-span-2"}    onClick = { bloc0_0_2_onClick } >{`A PROPOS`} </div></Link>
            <Link    to="/contacts"><div id='bloc0_0_3'  className={ "w-full p-2 bg-white font-semibold"}    onClick = { bloc0_0_3_onClick } >{`CONTACT`} </div></Link>
            <FontAwesomeIcon  id='bloc0_0_4'  icon={ fa.faCircleUser}      className={ "w-full p-2 bg-white w-10 h-9 w-9 h-12"}  />
            <div id='bloc0_0_5'  className={ "w-full p-2 bg-white font-semibold flex flex-row justify-between border-b-4 border-black"}     > <div id='bloc0_0_5_0'  className={ "p-2 bg-white"}     >{`Rechercher`} </div>
              <FontAwesomeIcon  id='bloc0_0_5_1'  icon={ fa.faSearch}      className={ "p-2 bg-white"}  /></div></div>
          <div id='bloc0_1'  className={ "flex flex-row justify-center bg-green-600"}     > <div id='bloc0_1_0'  className={ "p-2 flex flex-row items-center w-3/12 justify-center"}     > <div id='bloc0_1_0_0'  className={ "p-2 text-white font-bold"}     >{`SE MUSCLER`} </div>
              <FontAwesomeIcon  id='bloc0_1_0_1'  icon={ fa.faAngleDown}      className={ "p-2 text-white font-bold"}  /></div>
            <div id='bloc0_1_1'  className={ "p-2 flex flex-row items-center w-3/12 justify-center"}     > <div id='bloc0_1_1_0'  className={ "p-2 text-white font-bold"}     >{`S'AFFINER`} </div>
              <FontAwesomeIcon  id='bloc0_1_1_1'  icon={ fa.faAngleDown}      className={ "p-2 text-white font-bold"}  /></div></div></div><div id='bloc1'  className={ "bg-white flex flex-col items-center"}     > <div id='bloc1_0'  className={ "flex items-center justify-center sm:w-full md:w-5/12 px-3"}     > <img  id='bloc1_0_0'  className={ "flex justify-center items-center w-full   object-cover"}  src="https://fs.appisyou.com/users/14/pexels-fauxels1713792803692.jpg"   alt="undefined" /></div>
          <div id='bloc1_1'  className={ "flex mb-12 justify-center md:w-5/12 sm:w-full px-3"}     > <div id='bloc1_1_0'  className={ "text-lg text-justify w-full"}     >{`Bienvenue chez App is you, votre partenaire de confiance pour toutes vos solutions numériques innovantes. Nous sommes une équipe passionnée, dévouée à repousser les limites de la technologie pour offrir à nos clients des expériences numériques exceptionnelles.  À l'ère de la transformation digitale, nous comprenons que chaque entreprise a des besoins uniques. C'est pourquoi nous nous engageons à fournir des services personnalisés, adaptés à vos objectifs et à votre vision. Que vous soyez une start-up prometteuse ou une entreprise établie, notre expertise en matière de développement logiciel, de conception UX/UI, de marketing digital et de consulting stratégique vous permettra de prospérer dans un monde numérique en constante évolution.  Ce qui nous distingue, c'est notre approche centrée sur le client.   Chez App is you, l'innovation est au cœur de tout ce que nous faisons.`} </div></div></div><div id='bloc2'  className={ "bg-white w-screen"}     > <div id='bloc2_0'  className={ "w-full flex flex-row items-center justify-around bg-green-700 p-2"}     > <div id='bloc2_0_0'  className={ "p-2 text-white font-semibold w-full"}     >{`Mentions légales - CGU -CGV`} </div>
            <div id='bloc2_0_1'  className={ "w-full flex justify-around"}     > <FontAwesomeIcon  id='bloc2_0_1_0'  icon={ fa.faBabyCarriage}      className={ "text-white w-10 h-5"}  />
              <FontAwesomeIcon  id='bloc2_0_1_1'  icon={ fa.faBasketShopping}      className={ "text-white w-10 h-5"}  />
              <FontAwesomeIcon  id='bloc2_0_1_2'  icon={ fa.faBasketball}      className={ "text-white w-10 h-5"}  />
              <FontAwesomeIcon  id='bloc2_0_1_3'  icon={ fa.faBalanceScale}      className={ "text-white w-10 h-5"}  /></div></div></div></>
  
}

export default Component92Page;
